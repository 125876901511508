import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { getTotalListFilters } from '../../redux/propertiesDucks'
import { updateFields, formTasaciones_Tokko } from '../../redux/contactDucks'
import toast, { Toaster } from 'react-hot-toast';
import { graphql, useStaticQuery } from 'gatsby';
import ReCAPTCHA from "react-google-recaptcha";

const Form = ({totalListFilters,dispatch}) => {
    
    const type = useRef()
    const operation = useRef()
    const [datos, setDatos] = useState({
        nombre:'',
        email:'',
        ubicacion:'',
        sup_cub:'',
        sup_tot:'',
        rooms:'',
        garage:'',
        age:'',
        amenities:[]
    })
        
    const [disabled, setDisabled] = useState(true);
    const { realEstate } = useStaticQuery(graphql`
        query {
            realEstate {
                keys {
                    captcha
                }
            }
    }`)
                
    const handleInputChange = (event) => {
        if(event.target.name == 'amenities'){
            if(datos.amenities.find(element => element === event.target.value)){
                const newAmenities = datos.amenities.filter(element => element !== event.target.value)
                setDatos({
                    ...datos,
                    [event.target.name] : newAmenities, 
                })
                return null
            }
        setDatos({
            ...datos,
            amenities : [...datos.amenities,event.target.value], 
        })
            return null
        }
        setDatos({
            ...datos,
            [event.target.name] : event.target.value, 
        })
        
    }
    const clearForm= ()=>{
        setDatos({
        nombre:'',
        email:'',
        ubicacion:'',
        rooms:'',
        message:''
        })
    }
    const sendForm = (event) => {
        event.preventDefault()
        dispatch(formTasaciones_Tokko(type.current.value,operation.current.value,getUtm()))
        window.scrollTo(0, 0)
        document.getElementById("formContact").reset()
        return toast.success("¡Mensaje enviado correctamente!") 
    }
    useEffect(() => {
        const update = () => {
            dispatch(updateFields(datos))
        }
        update()
    }, [datos])

    useEffect(() => {
        // if(!realEstate?.keys?.captcha){
        //     setDisabled(false)
        //   }
        // else{
        //     setDisabled(true)
        // }
        dispatch(getTotalListFilters())
    },[])

    const getUtm = (customs = []) => {
        if(typeof window !== 'undefined'){
            var queryString = window.location.search
            var urlParams = new URLSearchParams(queryString)
            const tags = [customs]
            for (const [key, value] of urlParams) {
                if(key.includes("source")){tags.push("Origen: " + value)}
                if(key.includes("medium")){tags.push("Pieza: " + value)}
                if(key.includes("campaign")){tags.push("Campaña: " + value)}
            }
            return tags
        }
    }

    return (
        <>
        <form onSubmit={sendForm} id="formContact" class="row content-form ajax-form">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-6 pr-lg-3 pr-lg-2 mb-4 mb-lg-4">
                        <input onChange={handleInputChange} type="text" placeholder="Ubicación" name="ubicacion" id="ubicacion" />
                    </div>
                    <div class="col-lg-6 pr-lg-3 pl-lg-2 mb-4 mb-lg-4 position-relative">
                        <select ref={operation} name="operation">
                            <option value="">Tipo de operación</option>
                            {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 1) ? <option value="Venta">Venta</option> : ''}
                            {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 2) ? <option value="Alquiler">Alquiler</option> : ''}
                            {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 3) ? <option value="Alquiler Temporario">Alquiler Temporario</option> : ''}
                        </select>
                        <i></i>
                    </div>
                    <div class="col-lg-6 pr-lg-3 mb-4 mb-lg-4 position-relative">
                        <select ref={type} name="type">
                            <option value="">Tipo de propiedad</option>
                            {totalListFilters.data ? 
                                totalListFilters.data.objects.property_types.map((item,index) => (
                                    <option value={item.type}>{item.type}</option>
                                    )
                                )
                                :''
                            }
                        </select>
                        <i></i>
                    </div>
                    <div class="col-12 pr-3 camp ambientes">
                        <div class="row">
                            <div class="col-lg-6">
                            <label> Dormitorios</label>
                                <div class="ticks ticks-amb d-flex justify-content-between align-items-center">
                                    <span class="option">
                                        <input onChange={handleInputChange} type="radio" id="amb-1" name="rooms" value="1" />
                                        <label for="amb-1" class="mb-0"><span class="square">1</span></label>
                                    </span>  
                                    <span class="option">
                                        <input onChange={handleInputChange} type="radio" id="amb-2" name="rooms" value="2" />
                                        <label for="amb-2" class="mb-0"><span class="square">2</span></label>
                                    </span>  
                                    <span class="option">
                                        <input onChange={handleInputChange} type="radio" id="amb-3" name="rooms" value="3" />
                                        <label for="amb-3" class="mb-0"><span class="square">3</span></label>
                                    </span>  
                                    <span class="option">
                                        <input onChange={handleInputChange} type="radio" id="amb-4" name="rooms" value="4" />
                                        <label for="amb-4" class="mb-0"><span class="square">4</span></label>
                                    </span>  
                                    <span class="option">
                                        <input onChange={handleInputChange} type="radio" id="amb-5" name="rooms" value="5" />
                                        <label for="amb-5" class="mb-0"><span class="square">5</span></label>
                                    </span>  
                                    <span class="option">
                                        <input onChange={handleInputChange} type="radio" id="amb-6" name="rooms" value="6" />
                                        <label for="amb-6" class="mb-0"><span class="square">+</span></label>
                                    </span> 
                                </div>	
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 camp mb-4">
                        <textarea onChange={handleInputChange} name="message"  placeholder="Comentarios" cols="30" rows="10"></textarea>
                    </div>
                    <div class="col-lg-6 camp mb-0">
                        <input onChange={handleInputChange} type="text" required name="nombre"  placeholder="Nombre y apellido *" />
                    </div>
                    <div class="col-lg-6 camp mb-0">
                        <input onChange={handleInputChange} type="text" required name="email"  placeholder="Email *" />
                    </div>
                    <div class="col-lg-12 camp">
                        <p class="mt-1 text-end">*Campos obligatorios</p>
                        <div className="d-flex justify-content-center">
                        {
                            realEstate?.keys?.captcha ? 
                            <ReCAPTCHA
                            sitekey={realEstate?.keys?.captcha ? realEstate?.keys?.captcha : 0}
                            onChange ={() => setDisabled(false)}
                            /> 
                            : 
                            '' 
                        }
                        </div>
                        <button 
                        disabled={disabled} 
                        type="submit" id="submit-info"
                            class="btn btn-red d-flex submit justify-content-center align-items-center mt-4 mx-auto">
                            ENVIAR
                        </button>
                    </div>
                </div>
            </div>
        </form>
        <div id="alert-message"></div>
    </>
    )
}

export default connect(state => ({
    totalListFilters: state.properties.totalListFilters,
}),null)(Form);