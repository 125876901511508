import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

//Components
import Banner from '../components/Banner'
import Main from '../components/Tasaciones/main'
import BannerTop from "../components/Modules/MediaPush/BannerTop"

const IndexPage = () => (
  <Layout>
    <Seo title="Tasaciones" />
    <BannerTop page="tasaciones"/>
    <Banner
      section="tasaciones" 
      title="Tasaciones"
      subtitle="Desde 1984 brindamos el mejor servicio inmobiliario del mercado, asesorándote personalmente sin compromiso, en todas tus operaciones inmobiliarias. "/>
    <Main />
  </Layout>
)

export default IndexPage
