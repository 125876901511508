import React from 'react'
import Formulario from './form'
import VideoSection from '../VideoSection'
import Breadcrumbs from '../breadcrumbs';
import { graphql, useStaticQuery } from 'gatsby';
import VideoTablet from '../VideoTablet';

const Main = () => {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
              sections {
                    services{
                        title
                        subtitle
                        paragraph
                        form
                    }
              }
          }
    }`)


    return realEstate.sections &&
      realEstate.sections.services.form ? (
        <section class="tasaciones-main">
            <div class="container-fluid">
                <div class="row mt-lg-4 center align-items-center">
                    <div class="col-12">
                        <div class="row content-main align-items-start">
                            <div className="col-12 text-center">
                               <Breadcrumbs
                                   props={[
                                   { name: realEstate.name, route: "/", location: "" },
                                   { name: "Tasaciones", route: "", location: ""},
                                   ]}
                               />
                           </div>
                           <div className="col-12">
                                <h1 class="mb-5 text-center">
                                UNA TASACIÓN CORRECTA ES EL PRIMER PASO <br className="d-lg-block d-none" /> PARA UNA OPERACIÓN EXITOSA
                                </h1>
                                <VideoTablet videoSrc={<iframe  src="https://www.youtube.com/embed/CPrknBjE3Ck?controls=0&autoplay=0&rel=0&showinfo=0&modestbranding=1" title="YouTube video player" showinfo="0" rel="0" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>} />
                           </div>
                            <div className="col-12 mb-3 pb-lg-3">
                                <div className="separator"></div>
                            </div>
                            <div class="col-12 d-flex flex-column align-items-center">
                                <div className="form-container">
                                    <h1 class="mb-lg-5 text-center">
                                        COMPLETÁ EL FORMULARIO Y UNO DE NUESTROS REPRESENTANTES SE ESTARÁ COMUNICANDO A LA BREVEDAD
                                    </h1>
                                    <Formulario />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ):''
}

export default Main